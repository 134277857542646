import React from "react";
import "./ContactUs_module.css";

export const ContactUs = () => {
  return (
    <>
      <section className="contact-section">
        <div className="contact-bg">
          <h2>تواصل معانا</h2>
          {/* <div className="line">
            <div></div>
            <div></div>
            <div></div>
          </div> */}
          <p className="text"></p>
        </div>

        <div className="contact-body">
          <div className="contact-info">
            <div>
              <span>
                <i className="fas fa-mobile-alt"></i>
              </span>
              <span> تواصل معانا</span>
              <span className="text"> +966 0503003806</span>
            </div>
            <div>
              <span>
                <i className="fas fa-envelope-open"></i>
              </span>
              <span>راسلنا على</span>
              <span className="text">care@ataatre.com</span>
            </div>
            <div>
              <span>
                <i className="fas fa-map-marker-alt"></i>
              </span>
              <span>العنوان</span>
              <span className="text">
                4599 طريق الفرع الدائري الشمالي - النخيل - الرياض - المملكة
                العربية السعودية
              </span>
            </div>
            <div>
              <span>
                <i className="fas fa-clock"></i>
              </span>
              <span> مواعيد العمل</span>
              <span className="text" dir="rtl">
                السبت - الخميس <br />
              </span>
              (9 AM : 5 PM)
            </div>
          </div>
        </div>
        <div className="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3623.4127083568073!2d46.61386831480478!3d24.747034984107525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjTCsDQ0JzQ5LjMiTiA0NsKwMzYnNTcuOCJF!5e0!3m2!1sen!2seg!4v1678951040606!5m2!1sen!2seg"
            width="100%"
            height="450"
          ></iframe>
        </div>
      </section>
    </>
  );
};
