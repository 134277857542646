import HeroSlider, { Overlay, Slide, MenuNav } from "hero-slider";
import Wrapper from "../Subtitle/Subtitle";
import Title from "../Title/Title";
import Subtitle from "../Wrapper/Wrapper";
import bg1 from "../../image/HeroImg/bg1.jpg";
import bg2 from "../../image/HeroImg/bg2.jpeg";
import bg3 from "../../image/HeroImg/bg3.jpeg";
import bg4 from "../../image/HeroImg/bg4.jpeg";

// const one ={bg1}
// const two ={bg2}
// const three ={bg3}
// const four ={bg4}
export default function BasicSlider() {
  return (
    <HeroSlider
      height={"85vh"}
      autoplay
      controller={{
        initialSlide: 1,
        slidingDuration: 500,
        slidingDelay: 100,
        // onSliding: (nextSlide) =>
        //   console.debug("onSliding(nextSlide): ", nextSlide),
        // onBeforeSliding: (previousSlide, nextSlide) =>
        //   console.debug(
        //     "onBeforeSliding(previousSlide, nextSlide): ",
        //     previousSlide,
        //     nextSlide
        //   ),
        // onAfterSliding: (nextSlide) =>
        //   console.debug("onAfterSliding(nextSlide): ", nextSlide),
      }}
    >
      <Overlay>
        <Wrapper>
          <Subtitle>
            <Title>مثاب العقارية</Title>
            اكبر الشركات في تاجير الشقق الفاخره
          </Subtitle>
        </Wrapper>
      </Overlay>

      <Slide
        shouldRenderMask
        label="الين حطين"
        background={{
          backgroundImageSrc: bg1,
        }}
      />

      <Slide
        shouldRenderMask
        label="صفا عرقة"
        background={{
          backgroundImageSrc: bg2,
        }}
      ></Slide>

      <Slide
        shouldRenderMask
        label="
        نخيل - تركي 1"
        background={{
          backgroundImageSrc: bg3,
        }}
      />

      <Slide
        shouldRenderMask
        label="بدور حطين"
        background={{
          backgroundImageSrc: bg4,
        }}
      />
      <MenuNav />
    </HeroSlider>
  );
}
