import React from "react";
import "./About.css";
import logo from "../../image/logo.png";
export const AboutUs = () => {
  return (
    <>
      <section className="aboutus " id="about">
        <div className="container  p-5">
          <div className=" text-center  p-5 fw-bold text-white ">
            <div className="">
              <div className="an d-flex justify-content-center align-items-center">
                <h2 className="h2 aboutTitle ">من نحن</h2>
              </div>
            </div>
            <div className="titleLine  mt-3 mb-3"></div>
          </div>
          <div className="row   d-flex aboutarea  ">
            <div className="col-md-12 p-3   ">
              <div className="text-white text-center mb-5 animate__fadeInDownBig">
                <img src={logo} className=" pt-3 m-auto imageHandle" />
                <h2 className="italic">مـــــــثاب</h2>
                <h3 className="italic">يالله حيّهم</h3>
              </div>
              <div className="group ">
                <div className="d-flex justify-content-center text-white">
                  <div className="text-center p-5 fw-bold">
                    <h4 className="fw-bold">
                    نهدف الى تحقيق قمة الراحة النفسية للنزلاء من خلال تغيير
                    معايير الضيافة في المملكة العربية السعودية بما يواكب توجه
                    حكومتنا الرشيدة في وضع مدينة الرياض على الخارطة السياحة
                    والتجارية في العالم والارتقاء بمستوى الايواء والمساكن في
                    قطاعات السياحة والترفيه والاعمال. توفر مثاب شقق للتأجير
                    الشهري مؤثثة ومخدومة بالكامل تشمل كل ما يحتاجه النزيل سواء
                    كان سائح أو صاحب اعمال
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
