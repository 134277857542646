import React from "react";
import "./Footer.css";
import { useNavigate } from "react-router-dom";

export const Footer = () => {
  const navigate = useNavigate();

  const bedorRoom = () => {
    navigate("bedorroom");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const ellanRoom = () => {
    navigate("ellanroom");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const NahelRoom = () => {
    navigate("nahelroom");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const safaRooms = () => {
    navigate("safaRoom");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <footer className="">
        <div className="container bgFooter text-white ">
          <div className="row text-center  d-flex justify-content-center ">
            <div className="col-md-3   ">
              <h4 className="mb-4 fw-bold text-warning">الشقق </h4>
              <p className="text-white links" onClick={ellanRoom}>
                الين حطين
              </p>
              <p className="text-white handlLink links" onClick={safaRooms}>
                صفا عرقة
              </p>
              <p className="text-white handlLink links" onClick={bedorRoom}>
                بدور حطين
              </p>
              <p className="text-white handlLink links" onClick={NahelRoom}>
                نخيل - تركي 1
              </p>
            </div>
            <div className="col-md-6  ">
              <h4 className="mb-4 fw-bold text-warning me-5 ">تواصل معانا </h4>
              <div className="d-flex ">
                <div className="infoArea col-md-5">
                  <h5 className="mb">العنوان</h5>
                  <hr className="w-50 m-auto mt-1 mb-1" />
                  <p className="mb-0 mt-0">4599</p>
                  <p className="m-0">طريق الفرع الدائري الشمالي </p>
                  <p className="m-0">الرياض / النخيل</p>
                  <p className="m-0">المملكةالعربية السعودية</p>
                  <hr />
                  <h4 className="mb-0">البريد الالكتروني</h4> 
                  <hr className="w-50 m-auto mt-1 mb-1" />
                  <p className="mb-0 mt-0">care@ataatre.com</p>
                  <hr />
                  <h4 className="mb-0"> رقم الهـاتف</h4> 
                  <hr className="w-50 m-auto mt-1 mb-1" />
                  <p className="mb-0 mt-0">+966 0503003806</p>
                  <hr />
                </div>
                <div className="formArea col-md-7">
                  <form action="">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="p-2 rounded-2 inputCustom mt-2 text-end"
                      placeholder="البريد الالكتروني"
                    />
                    <input
                      type="text"
                      name="text"
                      id="text"
                      className="p-2 rounded-2 inputCustom mt-2 text-end"
                      placeholder="ألاســـم"
                    />

                    <input
                      type="texta"
                      className="p-2 rounded-2 inputCustom mt-2 text-end"
                      placeholder="العــنوان"
                    />
                    <input
                      type="number"
                      name="phone"
                      id="phone"
                      className="p-2 rounded-2 inputCustom text-end mt-2"
                      placeholder="رقــم الهاتف"
                    />
                    <input
                      type="text"
                      className="p-2 w-75 rounded-2 inputCustom text-end mt-2"
                      placeholder="عنــوان الرســاله"
                    />
                    <textarea
                      type="textarea"
                      name="textarea"
                      className="p-2 w-75 rounded-2 inputCustom text-end mt-2"
                      placeholder="اكتب رسالتك"
                    />
                    <br />
                    <button className="btn btn-warning mt-2">ارسال</button>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-3 ">
              <h4 className="mb-4 fw-bold text-warning">من نحن</h4>
              <p className="handlLink">
                مثاب تخدم المملكة العربية السعودية بما يواكب رؤية حكومتنا
                الرشيدة في الارتقاء بمستوى الايواء والمساكن في قطاعي السياحة
                والترفيه من خلال توفير شقق سكنية فاخرة بخدمات متكاملة للزيارات
                القصيرة والمتوسطة والطويلة الأجل على كيف كيفك
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
